import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Writings`}</h2>
    <ProjectCard title="Hosting two or more Blog..." link="https://medium.com/@vijaypushkin/hosting-two-or-more-ghost-blog-from-a-single-server-2019-a9eee2e575bf" bg="url(https://miro.medium.com/max/1200/1*YcDfgZXt6Nfd8ZfhaidV0A.jpeg)" bgFit="center top" mdxType="ProjectCard">
  This tutorial is a complete beginner's guide on how to create two or more
  ghost blogs from a single server (2019)
    </ProjectCard>
    <ProjectCard title="Creating cross platform desktop app from scratch..." link="https://medium.com/@vijaypushkin/hosting-two-or-more-ghost-blog-from-a-single-server-2019-a9eee2e575bf" bg="url(https://miro.medium.com/max/2000/1*QRAcW7OsgY8CRYHgwzQSRQ.jpeg)" bgFit="center" mdxType="ProjectCard">
  Creating a full fledged desktop application using Electron and web
  technologies for beginners within an hour or two
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      