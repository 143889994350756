import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The English language can not fully capture the depth and complexity of my thoughts. So I'm incorporating Emoji into my speech to better express myself. `}<span role="img" aria-label="wink">{`😉`}</span></p>
    </blockquote>
    <p>{`You know the way you feel when you see a picture of two otters holding hands? That's how you're gonna feel every day. You should make me your software consultant or perhaps philosophical consultant? `}<span role="img" aria-label="smile">{`😄`}</span>{`. I was born for philosophy. I have great beard and I love lying. Captain? The kids want to know where Paulie the Pigeon is. I told them he got sucked up into an airplane engine, is that all right?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      